import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "flex items-center px-5 py-5 sm:py-3 border-b border-primary-blue bg-primary-blue rounded-t" }
const _hoisted_3 = { class: "font-bold text-lg text-center mr-auto text-white" }
const _hoisted_4 = { class: "p-5" }
const _hoisted_5 = { class: "px-5 py-4 border-t border-gray-400" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_xIcon = _resolveComponent("xIcon")!

  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.modalId,
    class: "modal p-0"
  }, [
    _createElementVNode("div", {
      class: _normalizeClass('modal__content modal__content--' + _ctx.size)
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.title), 1),
        _createElementVNode("div", {
          href: "javascript:;",
          class: "right-0 top-0 cursor-pointer",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.hideModal && _ctx.hideModal(...args)))
        }, [
          _createVNode(_component_xIcon, { class: "w-6 h-6 text-gray-500 hover:text-white" })
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _renderSlot(_ctx.$slots, "content")
      ]),
      _createElementVNode("div", _hoisted_5, [
        _renderSlot(_ctx.$slots, "buttons")
      ])
    ], 2)
  ], 8, _hoisted_1))
}