
import { defineComponent, onMounted } from "vue";
import { gpf } from "@/utils/global-functions";

export default defineComponent({
  props: {
    modalId: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "lg",
    },
    title: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const hideModal = () => {
      gpf.hideModal(`#${props.modalId}`);
    };

    return {
      hideModal,
    };
  },
});
